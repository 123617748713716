import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/system';

export default function Intro() {
  const theme = useTheme();
  
  return (
        <Box
              id="intro" sx={{
          pt: { xs: 4, sm: 15 },
          pb: { xs: 8, sm: 30 },
          color: 'white',
          bgcolor: 'hsl(210,36%,96%)',
            marginTop: '120px'
        }}>
          <Container
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: { xs: 4, sm: 8 },
                }}
          >
            <Box
                  sx={{
                    width: { sm: '80%'},
                    textAlign: { sm: 'left', md: 'center' },
                  }}
            >
                {/*<Typography component="h2" variant="h3" sx={{ color: 'text.secondary' }}>
                    Sophkos provides AI-driven onboarding and transaction monitoring solutions
                </Typography><br/><br/>*/}
              <Typography component="h2" variant="h5" sx={{ color: 'hsl(210,11%,15%)' }}>
                  We had started as a real estate-related company but soon have discovered that, in the complex world of today, Fintech Lab can shield its customers from a wide range of fraudsters in multiple scenarios, not just in real estate-related transactions, but also covering a wider financial sector. Fintech Lab offers AI-powered fraud prevention solutions to global financial institutions.
              </Typography>
            </Box>
          </Container>
        </Box>
  );
}
